
import { useEffect, useState, useRef } from "react"
import { useParams, useLocation, useNavigate, Link } from "react-router-dom"

import {
    Tabs,
    Space,
    Button,
    Dropdown,
    Form,
    Modal,
    Input,
    notification
} from 'antd';

import {
    CaretDownOutlined
} from '@ant-design/icons'


import { DiventryMenu } from '../../../Templates/Menu'
import {
    DiventryLayoutDohzel,
    DiventryLayoutHeader,
    DiventryLayoutContent,
    DiventryLayoutFooter,
    DiventryBlock,
    DiventrySide
} from '../../../Templates/Layout'

import Logo from '../../../Images/logoSmall.png'

import { Settings } from './Settings'
import { Logs } from './Logs'
import { Devices } from './Devices'
import { Statistics } from './Statistics'
import { Subscriptions } from './Subscriptions';

export function Null() {
    return (<></>)
}

const pages = {
    'statistics': {
        key: 'statistics',
        title: "Statistics",
        description: "Securely exchange and share identity on Hafnova software",
        ref: Statistics
    },

    // 'security': {
    //     key: 'security',
    //     title: "Security & Filtering",
    //     description: "Emails addresses linked to your account",
    //     ref: Security
    // },
    'devices': {
        key: 'devices',
        title: "Devices",
        description: "Securely exchange and share identity on Hafnova software",
        ref: Devices
    },
    'logs': {
        key: 'logs',
        title: "Logs",
        description: "Securely exchange and share identity on Hafnova software",
        ref: Logs
    },
    // 'subscriptions': {
    //     key: 'subscriptions',
    //     title: "Subscriptions",
    //     description: "Securely exchange and share identity on Hafnova software",
    //     ref: Subscriptions
    // },
    // 'allowlist': {
    //     key: 'allowlist',
    //     title: "Allow list",
    //     ref: Allowlist
    // },
    // 'blocklist': {
    //     key: 'blocklist',
    //     title: "Block list",
    //     description: "Securely exchange and share identity on Hafnova software",
    //     ref: Blocklist
    // },
    'settings': {
        key: 'settings',
        title: "Settings",
        description: "Securely exchange and share identity on Hafnova software",
        ref: Settings
    },
    // 'installation': {
    //     key: 'installation',
    //     title: "Installation",
    //     description: "Your personnal information",
    //     ref: Null
    // },
}


export function Profile({ kernel }) {
    const navigate = useNavigate()
    const location = useLocation()
    const params = useParams()

    var ignore = false
    const [loaded, setLoaded] = useState(true)
    const [tabs, setTabs] = useState([])

    const [profiles, setProfiles] = useState([])
    const [profile, setProfile] = useState({})
    const [profileMenu, setProfileMenu] = useState([])

    const [modalCreate, setModalCreate] = useState(false)
    const [theForm] = Form.useForm()

    useEffect(() => {
        if (ignore)
            return

        async function fetch() {
            var response = await kernel.api.get("/dohzel/profile/me")
            if (response.error) {
                notification.error({
                    message: `Error fetching information`,
                    description: response.error
                })

                return
            }
            setProfiles(response.data.list)

            if (!params.id) {
                const dft = response.data.list[0]
                navigate(`/dohzel/profile/${dft.id}/${params.page1 || "statistics"}`)

                return
            }
            else {
                var current = await kernel.api.get(`/dohzel/profile/get?id=${params.id}`)
                if (current.error) {
                    notification.error({
                        message: `Error fetching information`,
                        description: current.error
                    })
                    navigate(`/dohzel/profile`)

                    return
                }
                setProfile(current.data)
            }

            // prepapre profile menu
            const menu = []
            for (let item of response.data.list) {
                menu.push({
                    label: item.name,
                    key: 'profile-' + item.id,
                    onClick: () => {
                        var url = `/dohzel/profile/${item.id}/${params.page1 || "statistics"}`
                        if (params.page2)
                            url += `/${params.page2}`
                        setLoaded(!loaded)
                        navigate(url)
                    },
                    disabled: params.id === item.id,
                })
            }
            menu.push({
                type: 'divider',
            })
            menu.push({
                label: 'Create a new profile',
                key: 'create-profile',
                onClick: () => {
                    theForm.resetFields()
                    setModalCreate(true)
                }
            })
            setProfileMenu(menu)
            setTabs(Object.values(pages))

        }


        fetch()

        return (() => {
            ignore = true
        })
    }, [params, loaded])

    async function createProfileOk() {
        const data = theForm.getFieldsValue()

        var response = await kernel.api.post("/dohzel/profile/create", data)
        if (response.error) {
            notification.error({
                message: `Error creating profile`,
                description: response.error
            })
            return
        }

        notification.success({
            message: `New profile created`
        })

        setModalCreate(false)

        navigate(`/dohzel/profile/${response.data.id}/${params.page1 || "statistics"}`)
    }

    async function createProfileCancel() {
        setModalCreate(false)
    }

    async function updateProfile() {
        setLoaded(!loaded)
    }

    const handleTabClick = (event, key) => {
        if (event.ctrlKey || event.metaKey) { // metaKey pour Cmd sur Mac
          window.open(`/#/dohzel/profile/${params.id}/${key}`);
          event.preventDefault(); // Empêche l'action par défaut
        }
    };

    return (
        <DiventryLayoutDohzel>

            <Modal
                title="Creating a new profile"
                open={modalCreate}
                onOk={createProfileOk}
                onCancel={createProfileCancel}
            >
                <Form form={theForm}>
                    <Form.Item label="Profile name" name="name">
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>

            <DiventryMenu kernel={kernel} />
            <DiventryLayoutHeader
                title={<Space align="center">
                    <img src={Logo} width={50} />
                    <div>{profile.name}</div>
                    <div style={{ paddingBottom: 20 }}>
                        <Dropdown
                            placement="bottomRight"
                            menu={{
                                items: profileMenu,
                            }}
                        >
                            <Button size="small" type="default"><CaretDownOutlined /></Button>
                        </Dropdown>
                    </div>
                </Space>}
                description="DoHzel management console"
            // description={page?.description}
            />
            <DiventryLayoutContent>
                <DiventryBlock>
                    <Tabs
                        type="card"
                        activeKey={params.page1}
                        defaultActiveKey={params.page1}
                        onChange={(tab) => {
                            navigate(`/dohzel/profile/${params.id}/${tab}`)
                        }}
                        onTabClick={(key, event) => handleTabClick(event, key)}
                        items={tabs.map((item) => {
                            return ({
                                key: item.key,
                                label: item.title,
                                forceRender: true,
                                children: <div style={{paddingTop: 10}}>
                                    <item.ref kernel={kernel} profile={profile} updateProfile={updateProfile} />
                                    </div>
                            })
                        })}
                    />
                </DiventryBlock>
            </DiventryLayoutContent>

            <DiventryLayoutFooter kernel={kernel}/>

        </DiventryLayoutDohzel>
    )
}