
import 'antd/dist/reset.css'
import './App.css'
import { useEffect, useState } from 'react'

import {
    HashRouter,
    BrowserRouter,
    Route,
    redirect,
    Routes,
    Navigate,
    useLocation
} from "react-router-dom"

import { ConfigProvider } from 'antd'
import anime from 'animejs'

import * as Sentry from "@sentry/react"

import { Kernel } from './Kernel'
import * as Pages from './Pages'

import * as ThreatDB from './Pages/ThreatDB/index'
import * as CyberIntel from './Pages/CyberIntel/index'
import * as DoHzel from './Pages/DoHzel'
import * as Plans from './Pages/Plans'
import MobilePage from './Pages/MobilePage'

export var Config = {}


String.prototype.ucFirst = function () {
    return (this.charAt(0).toUpperCase() + this.slice(1))
}

Array.prototype.random = function (seed) {
    var randomIndex;
    if (seed !== undefined) {
        var random = function () {
            var x = Math.sin(seed++) * 10000;
            return x - Math.floor(x);
        };
        randomIndex = Math.floor(random() * this.length);
    } else {
        randomIndex = Math.floor(Math.random() * this.length);
    }
    return this[randomIndex];
}


console.log("Environment: " + process.env.REACT_APP_ENV)
if (process.env.REACT_APP_ENV === "development") {
    Config = {
        // central: "http://127.0.0.1:10000/api",
        central: "https://api.dev.hafnova.com/api",
        counterMeasureList: "668011f287f961aa297caa29",
        joinToken: "G6HsFMYZAsZi8rsv6vTD",
        // central: "http://localhost:5689/api",
        ui: "http://127.0.0.1:3000/#",
        // ui: "http://10.181.12.15:3001/#",
        // sentry: {
        //     dsn: "https://94235c6a784a4968b9cce94bac1e2659@o4504621191790592.ingest.sentry.io/4504621265846272",
        //     tracesSampleRate: 1.0
        // }
    }
}
else if (process.env.REACT_APP_ENV === "preproduction") {
    Config = {
        central: "https://api.dev.hafnova.com/api",
        joinToken: "kY43V4k2PhTR5rTkrmvs",
        ui: "https://app.dev.hafnova.com",
        counterMeasureList: "668011f287f961aa297caa29",
        sentry: {
            dsn: "https://aead27a48e977be1673b74aec9669cbe@o4507396908318720.ingest.de.sentry.io/4507397270863952",
            integrations: [
                Sentry.browserTracingIntegration(),
                Sentry.replayIntegration(),
            ],
            // Performance Monitoring
            tracesSampleRate: 1.0, //  Capture 100% of the transactions
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: [/^https:\/\/app\.dev\.hafnova\.com/],
            // Session Replay
            replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        }
    }
}
else if (process.env.REACT_APP_ENV === "production") {
    Config = {
        central: "https://api.hafnova.com/api",
        joinToken: "G6HsFMYZAsZi8rsv6vTD",
        ui: "https://app.hafnova.com",
        counterMeasureList: "668015edacd146d640717b6d",
        sentry: {
            dsn: "https://bb585a76aa705044fc53cdef216901cd@o4507396908318720.ingest.de.sentry.io/4507397261754448",
            integrations: [
                Sentry.browserTracingIntegration(),
                Sentry.replayIntegration(),
            ],
            // Performance Monitoring
            tracesSampleRate: 1.0, //  Capture 100% of the transactions
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: [/^https:\/\/app\.hafnova\.com/],
            // Session Replay
            replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        }
    }
}
else {
    console.warning("No configuration found")
}

const Router = process.env.REACT_APP_ENV !== "development" ? BrowserRouter : HashRouter

// start sentry
if (Config.sentry)
    Sentry.init(Config.sentry)

export const kernel = new Kernel(Config)

export const Transition = (targets, onFinish, effect = "toRight", duration = 125) => {

    window.document.body.style.overflow = 'hidden'
    function end() {
        if (onFinish) onFinish()
        window.document.body.style.overflow = 'auto'
    }

    if (effect === "inRight") {
        anime({
            targets,
            easing: 'cubicBezier(.19, 1, 0.22, 1)',
            duration,
            // opacity: [0, 1],
            translateX: [window.innerWidth / 2, 0],
            complete: end
        })
    }
    else if (effect === "outRight") {
        anime({
            targets,
            easing: 'cubicBezier(.19, 1, 0.22, 1)',
            duration,
            // opacity: [0, 1],
            translateX: [0, window.innerWidth],
            complete: end
        })
    }
    else if (effect === "inLeft") {
        anime({
            targets,
            easing: 'cubicBezier(.19, 1, 0.22, 1)',
            duration,
            // opacity: [0, 1],
            translateX: [~window.innerWidth, 0],
            complete: end
        })
    }
    else if (effect === "outLeft") {
        anime({
            targets,
            easing: 'cubicBezier(.19, 1, 0.22, 1)',
            duration,
            // opacity: [0, 1],
            translateX: [0, ~window.innerWidth],
            complete: end
        })
    }
    else if (effect === "inFade") {
        anime({
            targets,
            easing: 'cubicBezier(.19, 1, 0.22, 1)',
            duration,
            opacity: [0, 1],
            complete: end
        })
    }
    else if (effect === "outFade") {
        anime({
            targets,
            easing: 'cubicBezier(.19, 1, 0.22, 1)',
            duration,
            opacity: [0, 1],
            complete: end
        })
    }
}


function App() {
    return (
        <div>
            <Router>
                <Content />
            </Router>
        </div>
    );
}

function Content() {
    const [loaded, setLoaded] = useState(true)
    const [loading, setLoading] = useState("Running kernel")
    const [fatal, setFatal] = useState(null)
    const [width, setWidth] = useState(window.innerWidth);

    const location = useLocation();
    const isSessionRoute = location.pathname.startsWith('/session');


    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);
    

    async function runKernel() {
        if (kernel.loaded === true) return
        kernel.on("log", setLoading)
        kernel.fatal = setFatal
        await kernel.init()
        kernel.remove("log", setLoading)
        setTimeout(() => {
            setLoading(null)

        }, 1000)
    }

    function splashLoaded() {
        runKernel()
    }
    
    return (
        <div>
            {
                width < 700  && !isSessionRoute ? 
                    <MobilePage/>
                :
                <>
                    {fatal ?
                        <Pages.Fatal kernel={kernel} activate={true}>
                            {fatal}
                        </Pages.Fatal>
                        :
                        <>
                            {loading ?
                                <Pages.Splash kernel={kernel} onFinish={splashLoaded}>{loading}</Pages.Splash>
                                :
                                <Routes>
                                    <Route path="/dashboard" element={<Pages.Dashboard kernel={kernel} />} />
                                    <Route path="/info" element={<Pages.Info kernel={kernel} />} />
                                    
                                    {/* Plans related routes */}
                                    <Route path="/plans" element={<Pages.Plans kernel={kernel} />} />
                                    <Route path="/plans/business" element={<Pages.Plans kernel={kernel} />} />
                                    <Route path="/plans/city" element={<Pages.Plans kernel={kernel} />} />
                                    <Route path="/plans/private" element={<Pages.Plans kernel={kernel} />} />

                                    {/* DoHzel related routes */}
                                    <Route path="/dohzel/profile/:id/:page1/:page2" element={<DoHzel.Profile kernel={kernel} />} />
                                    <Route path="/dohzel/profile/:id/:page1" element={<DoHzel.Profile kernel={kernel} />} />
                                    <Route path="/dohzel/profile/:page1" element={<DoHzel.Profile kernel={kernel} />} />
                                    <Route path="/dohzel/profile" element={<DoHzel.Profile kernel={kernel} />} />

                                    <Route path="/dohzel/device/:id/:page1/:page2" element={<DoHzel.Device kernel={kernel} />} />
                                    <Route path="/dohzel/device/:id/:page1" element={<DoHzel.Device kernel={kernel} />} />

                                    {/* CyberIntel related routes */}
                                    <Route path="/cyberintel/instances" element={<CyberIntel.Instance.List kernel={kernel} />} />
                                    <Route path="/cyberintel/instances/:id/:visionPage" element={<CyberIntel.Instance.Detail kernel={kernel} />} />

                                    {/* ThreatDB access related routes */}
                                    <Route path="/threatdb/ai/verify" element={<ThreatDB.AIVerify kernel={kernel} />} />
                                    <Route path="/threatdb/list" element={<ThreatDB.List.List kernel={kernel} />} />
                                    <Route path="/threatdb/search-ioc" element={<ThreatDB.List.SearchIOC kernel={kernel} />} />
                                    <Route path="/threatdb/list/detail/:id" element={<ThreatDB.List.Detail kernel={kernel} />} />
                                    <Route path="/threatdb/list/domains/:id/:page1" element={<ThreatDB.List.Domains kernel={kernel} />} />
                                    <Route path="/threatdb/list/ipv4/:id/:page1" element={<ThreatDB.List.IPv4 kernel={kernel} />} />
                                    <Route path="/threatdb/list/ipv6/:id/:page1" element={<ThreatDB.List.IPv6 kernel={kernel} />} />
                                    <Route path="/threatdb/:id/domains/:page1" element={<ThreatDB.List.Domains kernel={kernel} />} />
                                    <Route path="/threatdb/:id/ipv4/:page1" element={<ThreatDB.List.IPv4 kernel={kernel} />} />
                                    <Route path="/threatdb/:id/ipv6/:page1" element={<ThreatDB.List.IPv6 kernel={kernel} />} />
                                    <Route path="/threatdb/tags" element={<ThreatDB.Tags kernel={kernel} />} />
                                    <Route path="/threatdb/search" element={<ThreatDB.Search kernel={kernel} />} />
                                    <Route path="/threatdb/show/:type" element={<ThreatDB.Show kernel={kernel} />} />

                                    {/* Session related routes */}
                                    {/* <Route path="/session/select" element={<Pages.Session.Select kernel={kernel} />} /> */}
                                    <Route path="/session/login" element={<Pages.Session.Login kernel={kernel} />} />
                                    <Route path="/session/register" element={<Pages.Session.Register kernel={kernel} />} />
                                    <Route path="/session/forgot" element={<Pages.Session.Forgot kernel={kernel} />} />
                                    <Route path="/session/validate/:token" element={<Pages.Session.Validate kernel={kernel} />} />
                                    <Route path="/session/change-password/:token" element={<Pages.Session.ChangePassword kernel={kernel} />} />

                                    {/* Account management */}
                                    <Route path="/account/:visionPage" element={<Pages.Account.Account kernel={kernel} />} />

                                    {/* Administration route */}
                                    <Route path="/admin/tags" element={<ThreatDB.Tags kernel={kernel} />} />
                                    <Route path="/admin/dohzel-review" element={<DoHzel.Review kernel={kernel} />} />
                                    <Route path="/admin/dohzel-offer" element={<DoHzel.Offer kernel={kernel} />} />

                                    {/* Default route */}
                                    <Route
                                        path="*"
                                        element={<Navigate to="/dohzel/profile" replace />}
                                    />
                                </Routes>
                            }
                        </>
                    }

                </>
            }
        </div>
    );
}

export default App;
