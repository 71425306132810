import { useEffect, useRef } from "react";

import {
    Button,
    Descriptions,
    Form,
    Input,
    Space
} from 'antd';

import {
    DiventryBlock
} from '../../Templates/Layout';
import PasswordReset from "../../Templates/PasswordReset";
import { UtilsInputLang } from "../../Templates/Utils";

export function Information(props) {

    const formRef = useRef()
    useEffect(() => {
        formRef.current.setFieldsValue(props.values);
    }, [props.values]);

    return (<>
        <Form
            ref={formRef}
            name="complex-form"
            onFinish={props.onUpdate}
        >
            <DiventryBlock bottom={true}>
                <h2>Information</h2>

                <Descriptions bordered>
                    <Descriptions.Item label="First name">
                        <Form.Item name='firstName' noStyle>
                            <Input />
                        </Form.Item>
                    </Descriptions.Item>

                    <Descriptions.Item label="Last name">
                        <Form.Item name='lastName' noStyle>
                            <Input />
                        </Form.Item>
                    </Descriptions.Item>

                    <Descriptions.Item label="Language">
                        <Form.Item name='lang' noStyle>
                            <UtilsInputLang style={{ width: '100%' }} />
                        </Form.Item>
                    </Descriptions.Item>

                    <Descriptions.Item label="Phone">
                        <Form.Item name='phone' noStyle>
                            <Input />
                        </Form.Item>
                    </Descriptions.Item>

                    <Descriptions.Item label="Account ID" >
                        <Form.Item name='id' noStyle>
                            <Input disabled bordered={false} />
                        </Form.Item>
                    </Descriptions.Item>
                </Descriptions>
            </DiventryBlock>

            <DiventryBlock>
                <Space >
                    <Button type="primary" htmlType="submit">Save</Button>
                    <Button>Reset</Button>
                    <PasswordReset kernel={props.kernel} />
                </Space>
            </DiventryBlock>

        </Form >

    </>)
}
