import React, { useEffect, useState } from 'react';
import Cards from 'react-credit-cards-2';
import 'react-credit-cards-2/es/styles-compiled.css';
import {
    Button
} from 'antd'
import {
    DiventryBlock
} from '../../Templates/Layout'

export function  PaiementMethod({ onUpdate, values, kernel }) {

    const [list, setList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        (async () => {
            var list = await kernel.api.get("/user/payment-methods/list");
            setList(list?.data ?? []);

            setTimeout(() => {
                const elements = document.querySelectorAll('.rccs__number');
                elements.forEach(element => {
                    element.textContent = `**** **** **** ${element.textContent?.slice(-4)}`;
                });
            }, 50);
        })();
    }, []);

    const paymentMothodOnStripeDashboard = async () => {

        setIsLoading(true);

        var response = await kernel.api.post("/user/payment-methods/create-customer-portal-session", {});
        const { url } = await response;
        window.location.href = url;
      
        // if (response.error) {
        //     console.error(response.error.message);
        // }

        setTimeout(() => {
            setIsLoading(false);
        }, 1000)
    };

    const getFirst4 = (brand = 'visa') => {
        switch (brand.toLowerCase()) {
            case 'visa': 
                return 4242;
            case 'mastercard': 
                return 5555;
            case 'american express': 
            case 'amex': 
                return 3782;
            case 'discover': 
                return 6011;
            case 'diners club': 
                return 3056;
            case 'jcb': 
                return 3530;
            default:
                return 'Brand not supported';
        }
    };

    return (
        <DiventryBlock>
            <div style={{
                padding: 20,
                width: "100%",
                fontSize: 50,
                display: 'flex',
                justifyContent: 'space-evenly',
                flexWrap: 'wrap',
                fontWeight: 800
            }}>
                {
                    list?.map(item => (
                        <div style={{paddingBottom: 20}}>
                            <Cards
                                expiry={`${item?.card?.exp_month}/${item?.card?.exp_year}`}
                                preview={false}
                                name={`${values?.firstName} ${values?.lastName}`}
                                number={`${getFirst4(item?.card?.brand)}********${item?.card?.last4}`}
                            />
                        </div>
                    ))
                }
            </div>

            <center style={{marginBottom: 20}}>
                <Button type="primary" loading={isLoading} onClick={paymentMothodOnStripeDashboard}>{isLoading ? 'Processing...' : (list?.length == 0 ? 'Add a new payment method' : 'Manage my payment methods')}</Button>
            </center>

        </DiventryBlock>
    );
};
