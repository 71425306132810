import { useEffect, useState } from 'react'
import { useNavigate, useParams } from "react-router-dom"

import {
    Button,
    Tag, Space,
    Descriptions,
    Form,
    Input,
    Select,
    notification,
    Popconfirm
} from 'antd'

import { DiventryBlock } from '../../../../Templates/Layout'


export function General({ kernel, updateDevice, device }) {
    // main document
    const [edit, setEdit] = useState(false);

    // for source addition
    const [isModalSource, setIsModalSource] = useState(false);
    const [titleModalSource, setTitleModalSource] = useState("");
    const [sourceForm] = Form.useForm();

    // for update
    const [isModalUpdate, setIsModalUpdate] = useState(false);
    const [updateForm] = Form.useForm();
    const [license, setLicense] = useState(null);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        console.log('device : ', device);
        fetchLicense();
    }, [])

    const fetchLicense = async () => {
        try {
            const response = await kernel.api.get(`/user/subscriptions/device-license?deviceId=${device?.id}`);
    
            if (response.error) {
                setLicense(null)
                return;
            }
    
            // Traiter les données de la licence récupérées
            const licenseInfo = response.data;
            setLicense(licenseInfo);
    
        } catch (error) {
            notification.error({
                message: 'Error',
                description: error.message,
            });
        }
    }

    const takeLicense = async () => {
        setLoading(true);
        var deviceId = device?.id;
        var profileId = device?.profileId;
        try {
            const response = await kernel.api.post(`/user/subscriptions/assign-device`, {
                deviceId: deviceId,
                profileId: profileId,
                deviceType: device?.deviceType
            });
    
            if (response.error) {
                notification.error({
                    message: 'Error',
                    description: response.error,
                });
                setLoading(false);
                return;
            }
    
            notification.success({
                message: 'Success',
                description: 'Device assigned successfully!',
            });
        } catch (error) {
            notification.error({
                message: 'Error',
                description: error.message,
            });
        }
        await fetchLicense();
        setLoading(false);
    };

    const deleteLicense = async () => {
        setLoading(true);
        try {
            const response = await kernel.api.post(`/user/subscriptions/remove-device`, {
                id: license?.id,  // Passer l'ID de subscription_devices
            });
    
            if (response.error) {
                notification.error({
                    message: 'Error',
                    description: response.error,
                });
                setLoading(false);
                return;
            }
    
            notification.success({
                message: 'Success',
                description: 'Device unassigned successfully',
            });
        } catch (error) {
            notification.error({
                message: 'Error',
                description: error.message,
            });
        }
        await fetchLicense();
        setLoading(false);
    }

    return (
        <>
            <DiventryBlock >
                <Form form={sourceForm}>
                    <Descriptions
                        bordered
                        column={{
                            xxl: 2,
                            xl: 2,
                            lg: 2,
                            md: 1,
                            sm: 1,
                            xs: 1,
                        }}
                    >
                        <Descriptions.Item span={2} label="Device Name">
                            {edit === true ?
                                <Form.Item name="name" noStyle={true}>
                                    <Input />
                                </Form.Item>
                                :
                                <strong>{device.name}</strong>}
                        </Descriptions.Item>

                        {device.deviceType === "proxy" && device.hasOwnProperty("connectCap") ?
                            <Descriptions.Item span={2} label="DoHzel Connect">
                                <Tag color="blue" style={{cursor: "pointer"}} onClick={() => {
                                    navigate(`/dohzel/profile/${device.profileId}/settings/connect`)
                                }}>Goto DoHzel Connect</Tag>
                                {device.connectCap === true ?
                                    <Tag color="green" bordered={false}>Connected</Tag> :
                                    <Tag color="orange" bordered={false}>Disconnected</Tag>
                                }
                            </Descriptions.Item>
                            : null}

                        <Descriptions.Item span={2} label="DNS over HTTPS (DoH)">
                            {device.contactDOH}
                        </Descriptions.Item>

                        <Descriptions.Item span={2} label="DNS over TLS (DoT)">
                            {device.contactDOT}
                        </Descriptions.Item>

                        <Descriptions.Item label="Creation date">
                            {new Date(device.createdAt).toLocaleString()}
                        </Descriptions.Item>

                        <Descriptions.Item label="Last update">
                            {new Date(device.updatedAt).toLocaleString()}
                        </Descriptions.Item>

                        <Descriptions.Item label={`License ${license?.productName ? license?.productName : ''}`}>
                            {license?.assignedAt ? new Date(license?.assignedAt)?.toLocaleString() : 'no license'}
                        </Descriptions.Item>

                    </Descriptions>
                </Form>
            </DiventryBlock>

            <DiventryBlock>
                <Space.Compact>
                    {edit === true ?
                        <>
                            <Button type="primary" onClick={async () => {
                                const data = sourceForm.getFieldsValue();
                                data.id = device.id;
                                const ret = await kernel.api.post(`/dohzel/device/update`, data);
                                if (!ret) {
                                    notification.error({
                                        message: 'Service unavailable'
                                    });
                                    return;
                                }
                                if (ret.error) {
                                    notification.error({
                                        message: ret.error
                                    });

                                    if (ret.fields) {
                                        const fields = [];
                                        for (var key in ret.fields) {
                                            const message = ret.fields[key];
                                            const k = key.split(".");
                                            fields.push({ name: k[0], errors: message });
                                        }

                                        sourceForm.setFields(fields);
                                    }

                                    return;
                                }

                                notification.success({
                                    message: "Device information saved"
                                });

                                setEdit(false)
                                updateDevice()
                            }}>Save</Button>
                            <Button onClick={() => {
                                setEdit(false);
                            }}>Cancel</Button>
                        </>
                        :
                        <>
                            <Button type="primary" onClick={() => {
                                sourceForm.resetFields();
                                sourceForm.setFieldsValue(device);
                                setEdit(true);
                            }}>Edit</Button>
                            {
                                !license ?
                                <Button loading={loading} onClick={() => {
                                        takeLicense()
                                    }}>Attach a license
                                </Button>
                                :
                                <Popconfirm
                                    title="Cancel license"
                                    description="Are you sure to cancel license?"
                                    onConfirm={() => deleteLicense()}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button loading={loading} type='primary' danger>Release license </Button>
                                </Popconfirm>
                            }
                        </>
                        }
                </Space.Compact>
            </DiventryBlock>
        </>
    );
}
