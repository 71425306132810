
import { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";

import {
    Button,
    Modal,
    Space,
    Spin,
    Table,
    Tabs,
    Form,
    Input,
    Select,
    Tag,
    notification,
    theme
} from 'antd';

import {
    ReloadOutlined,
    LockOutlined
} from '@ant-design/icons';

import {
    DiventryBlock
} from '../../../../Templates/Layout';


import Brands from '../../Brands/Index'


const deviceTypesAdd = {
    ...Brands.DeviceTypes
}
delete deviceTypesAdd.appleMac
delete deviceTypesAdd.appleiOS
delete deviceTypesAdd.googleAndroid
delete deviceTypesAdd.windows
delete deviceTypesAdd.linux
delete deviceTypesAdd.freeBSD
delete deviceTypesAdd.proxy
delete deviceTypesAdd.browser

const { useToken } = theme;

export const LogsColors = {
    blocking: "4px solid #a14838",
    detecting: "4px solid #e58900",
    passed: "4px solid #22d933",
    bypassing: "4px solid #4974a5",
}




export function Devices({ kernel }) {
    const navigate = useNavigate()
    const params = useParams()
    const location = useLocation()

    const [loaded, setLoaded] = useState(true)
    const [loading, setLoading] = useState(false)

    const [profile, setProfile] = useState({})
    const [devices, setDevices] = useState([])

    const [enrollModal, setEnrollModal] = useState(false)
    const [enrollLoading, setEnrollLoading] = useState(false)

    const [addForm] = Form.useForm()
    const [addModal, setAddModal] = useState(false)

    const columns = [
        {
            title: 'Device',
            dataIndex: 'deviceType',
            key: 'deviceType',
            render: (text, record, index) => {
                return (<Brands.BrandedDevice device={record} />)
            }
        },
        {
            title: 'Last activity',
            dataIndex: 'lastContact',
            key: 'lastContact',
            render: (text, record, index) => {
                if (!text)
                    return ("-")
                return (new Date(text).toLocaleString())
            }
        },

        {
            title: 'Management',
            dataIndex: 'deviceManagement',
            key: 'deviceManagement',
            render: (text, record, index) => {

                if (text === "mdm")
                    return (<Tag
                        color="red"
                        bordered={false}
                        style={{
                            fontWeight: 700
                        }}>< LockOutlined /> Managed</Tag >)

                if (text === "free")
                    return (<Tag
                        color="green"
                        bordered={false}
                        style={{
                            fontWeight: 700
                        }}>< LockOutlined /> Free</Tag >)

                return ("-")
            }
        },
        // {
        //     title: 'Blocked',
        //     dataIndex: 'deviceManagement',
        //     key: 'deviceManagement',
        //     render: (text, record, index) => {
        //         return ("5451514")
        //     }
        // },
        // {
        //     title: 'Passed',
        //     dataIndex: 'deviceManagement',
        //     key: 'deviceManagement',
        //     render: (text, record, index) => {
        //         return ("5451514")
        //     }
        // },
        {
            // title: 'id',
            dataIndex: 'id',
            key: 'id',
            align: "right",
            render: (text, record, index) => {
                return (<Space.Compact size="small">
                    <Button type="default" onClick={() => {
                        localStorage.setItem("deviceBack", location.pathname + location.search)
                        navigate(`/dohzel/device/${record.id}/settings`)
                    }}>Settings</Button>
                    <Button type="default" onClick={() => {
                        localStorage.setItem("deviceBack", location.pathname + location.search)
                        navigate(`/dohzel/device/${record.id}/logs`)
                    }}>Logs</Button>
                    <Button type="default" onClick={() => {
                        localStorage.setItem("deviceBack", location.pathname + location.search)
                        navigate(`/dohzel/device/${record.id}/statistics`)
                    }}>Statistics</Button>


                </Space.Compact>)
            }
        },
    ]

    // async load
    useEffect(() => {
        if (!params.id)
            return

        async function fetchProfile() {
            var current = await kernel.api.get(`/dohzel/profile/get?id=${params.id}`)
            if (current.error) {
                notification.error({
                    message: `Error fetching information`,
                    description: current.error
                })
                navigate(`/dohzel/profile`)

                return
            }
            setProfile(current.data)
        }

        async function fetch() {
            setLoading(true)
            var response = await kernel.api.get(`/dohzel/device/list?profileId=${params.id}`)
            if (response.error) {
                notification.error({
                    message: `Error getting device list`,
                    description: response.error
                })
                return
            }
            setLoading(false)
            console.log(response.data)
            setDevices(response.data)
        }
        fetchProfile()
        fetch()
    }, [params.id, loaded])

    async function enrollOk() {

    }

    async function enrollCancel() {
        setEnrollModal(false)
    }

    async function enrollRefresh() {
        setEnrollLoading(true)
        var response = await kernel.api.get(`/dohzel/profile/changeEnrollCode?id=${params.id}`)
        setEnrollLoading(false)
        if (response.error) {
            notification.error({
                message: `Error generating enrolment tokens`,
                description: response.error
            })
            return
        }
        setLoaded(!loaded)
    }

    async function addOk() {
        const data = {
            ...addForm.getFieldsValue(),
            profileId: params.id
        }

        var response = await kernel.api.post(`/dohzel/device/create`, data)
        if (response.error) {
            notification.error({
                message: `Error adding device`,
                description: response.error
            })
            return
        }
        notification.success({
            message: `DoHzel device added`
        })
        setAddModal(false)
        setLoaded(!loaded)
    }

    async function addCancel() {
        setAddModal(false)
    }


    return (
        <>
            <Modal
                title="Enroll device"
                open={enrollModal}
                onOk={enrollCancel}
                onCancel={enrollCancel}
            >
                <Spin spinning={enrollLoading}>
                    <Tabs defaultActiveKey="1" type="card" items={[
                        {
                            key: 'enroll-tab-mdm',
                            label: 'Managed',
                            children: <div>
                                <div style={{ padding: 10 }}>
                                    <center>
                                        <Space>
                                            <div style={{ fontWeight: 800, fontSize: 34 }}>
                                                {profile.enrollMDM}
                                            </div>
                                            <Button onClick={enrollRefresh}>{!profile.enrollMDM ? "Generate" : null}<ReloadOutlined /></Button>
                                        </Space>
                                    </center>
                                </div>

                                <p>
                                    Managed enrolment ensures total device control with no limits. Enter this code in your DoHzel applications.
                                </p>
                                <ul>
                                    <li>The user cannot change modes.</li>
                                    <li>Device logs are merged to the profile logs.</li>
                                    <li>All profile options are forcibly applied to the device.</li>
                                    <li>The user cannot unsubscribe from the profile.</li>
                                    <li>Managed enrolment leaves the device no freedom.</li>
                                </ul>
                                <p>Use cases</p>
                                <ul>
                                    <li>When the profile contains only your devices.</li>
                                    <li>When the device is part of a professional fleet.</li>
                                    <li>When you want to enforce parental controls.</li>
                                </ul>
                            </div>,
                        },
                        {
                            key: 'enroll-tab-free',
                            label: 'Device Freedom',
                            children: <p>
                                <div style={{ padding: 10 }}>
                                    <center>
                                        <Space>
                                            <div style={{ fontWeight: 800, fontSize: 34 }}>
                                                {profile.enrollFREE}
                                            </div>
                                            <Button onClick={enrollRefresh}>{!profile.enrollFREE ? "Generate" : null}<ReloadOutlined /></Button>
                                        </Space>
                                    </center>
                                </div>

                                <p>
                                    The <strong>Device Freedom</strong> mode grants the user of the device the freedom to choose what they want to share or activate.
                                </p>
                                <ul>
                                    <li>The device user selects their mode.</li>
                                    <li>The user has the choice to merge their logs with those of the profile.</li>
                                    <li>They can also decide to partially share device information.</li>
                                    <li>The device has its own allow and block lists.</li>
                                    <li>The user chooses what to block.</li>
                                </ul>
                                <p>Use cases</p>
                                <ul>
                                    <li>When the administrator pays the subscription without controlling the device.</li>
                                    <li>When a company wants to offer DoHzel to its employees for their personal devices without compromising their privacy.</li>
                                </ul>
                            </p>,
                        },
                    ]} />

                    <small>
                        When you register a device using a code, the user will not have access to the profile, unless he/she manages to log in to said profile.
                    </small>
                </Spin>
            </Modal>

            <Modal
                title="Add manually a device"
                open={addModal}
                onOk={addOk}
                onCancel={addCancel}
            >
                <p>
                    Create a device and receive a DOH URL or DOT address.
                    This may be necessary when it is not possible to install the application on the target device.
                </p>

                <Form
                    form={addForm}
                >
                    <Form.Item label="Device Name" name="name">
                        <Input />
                    </Form.Item>

                    <Form.Item label="Device Type" name="deviceType">
                        <Select
                            style={{ width: '100%' }}
                            options={Object.keys(deviceTypesAdd).map((item) => {
                                return ({
                                    value: item,
                                    label: <Brands.BrandedDeviceLine type={item} />
                                })
                            })}
                        />
                    </Form.Item>
                </Form>
            </Modal>


            <p style={{marginTop: 10}}>
                {/* <Space.Compact> */}
                    <Button type="primary" onClick={() => setEnrollModal(true)}>Enroll a device</Button>
                    <Button type="primary" onClick={() => setAddModal(true)} style={{marginLeft: 5}}>Add a device</Button>
                {/* </Space.Compact> */}
            </p>

            <Table
                // bordered={true}
                pagination={{ position: ['topLeft', 'bottomRight'] }}
                ellipsis={true}
                loading={loading}
                showSizeChanger={true}
                dataSource={devices}
                columns={columns}
            />
        </>
    )
}

// DAMfitMra7lpxKV0fkXG9R8v2iyOE1BquQdSG9YEZGyxfeQY7Th9fWxfoSkb3Hf/599EpVqHrV2a2rWVtw==>fDwb3EelsBbla7I3>Wg+Hv12zLHTYa5J+imttDQ==