
import { useEffect, useState } from 'react'
import { Link, useNavigate } from "react-router-dom"
import { Content } from '../../Templates'
import {
    Table,
    Button,
    Tag,
    Space,
    Descriptions,
    Modal,
    Form,
    Input,
    Select,
    Popconfirm,
    notification,
    Switch,
    InputNumber,
    ColorPicker
} from 'antd'

import {
    SettingOutlined,
    DeleteOutlined
} from '@ant-design/icons'


import { DiventryMenu } from '../../Templates/Menu'
import {
    DiventryLayoutThreatDB,
    DiventryLayoutHeader,
    DiventryLayoutContent,
    DiventryLayoutFooter,
    DiventryBlock
} from '../../Templates/Layout'

export const Responses = {
    none: "Do nothing",
    notice: "Administration Notice",
    investigation: "Investigation Required",
}

const columns = [
    {
        title: 'Tag',
        dataIndex: 'name',
        key: 'name',
        render: (text, record, index) => {
            if (record.color)
                return (<Tag color={record.color}>{text}</Tag>)
            return (<Tag>{text}</Tag>)
        }
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
    },
    {
        title: 'Input',
        dataIndex: 'input',
        key: 'input',
        render: (text, record, index) => {
            return (Responses[text])
        }
    },
    {
        title: 'Output',
        dataIndex: 'output',
        key: 'output',
        render: (text, record, index) => {
            return (Responses[text])
        }
    },
    {
        title: 'DoHzel',
        dataIndex: 'dohzel',
        key: 'dohzel',
        render: (text, record, index) => {
            return (record.dohzel === true ? "Included" : "Excluded")
        }
    },
    {
        title: '',
        dataIndex: 'buttons',
        key: 'buttons',
        align: "right"
    },
]

export function Tags({ kernel }) {
    const [loaded, setLoaded] = useState(true)
    const [table, setTable] = useState([])

    // for list creation
    const [isModalCreate, setIsModalCreate] = useState(false)
    const [modalMode, setModalMode] = useState(null)
    const [createForm] = Form.useForm()

    const navigate = useNavigate()

    useEffect(() => {
        async function fetch() {
            const newTable = []
            const raw = await kernel.api.get('/threatdb/tags/list')
            if (!raw || raw.error) {
                notification.error({
                    message: `Fetch error`,
                    description: raw.error
                })
                return
            }

            const tagList = raw.data?.sort((a, b) => a.order - b.order);

            for (let data of tagList) {
                data.key = data.id

                data.buttons = <Space.Compact size="small">
                    <Popconfirm
                        placement="left"
                        title="Delete this tag"
                        description="The tag will be permanently deleted, no data will be affected"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={async () => {
                            const ret = await kernel.api.post(
                                `/threatdb/tags/remove`,
                                { id: data.key }
                            )
                            if (ret.error) {
                                notification.error({
                                    message: 'Error deleting key',
                                    description: ret.error
                                })
                                return
                            }
                            notification.success({
                                message: 'Tag was deleted'
                            })
                            setLoaded(!loaded)
                        }}
                    >
                        <Button><DeleteOutlined /></Button>
                    </Popconfirm>
                    <Button onClick={() => {
                        setModalMode(data.key)
                        createForm.resetFields()
                        createForm.setFieldsValue(data)
                        setIsModalCreate(true)
                    }}><SettingOutlined /></Button>
                </Space.Compact>

                newTable.push(data)
            }
            setTable(newTable)
        }
        fetch()
    }, [loaded])

    // Create handler
    const handleCreateOk = async () => {
        const data = {
            ...createForm.getFieldsValue(),
            order: createForm.getFieldsValue()?.order ?? (table?.length + 1)
        }

        if (data.color && data.color.toHexString)
            data.color = data.color.toHexString()

        if (modalMode === null) {
            const ret = await kernel.api.post(`/threatdb/tags/create`, data)
            if (!ret || ret.error) {
                notification.error({
                    message: `Creation error: ${ret.error}`,
                    description: Array.isArray(ret.fields) ? Object.values(ret.fields).map((el) => <div key={el}>{el}</div>) : ret.error
                })
                return
            }
            notification.success({
                message: 'New managed tag created'
            })
        }
        else {
            data.id = modalMode
            const ret = await kernel.api.post(`/threatdb/tags/update`, data)
            if (ret.error) {
                notification.error({
                    message: `Creation error: ${ret.error}`,
                    description: Array.isArray(ret.fields) ? Object.values(ret.fields).map((el) => <div key={el}>{el}</div>) : null
                })
                return
            }
            notification.success({
                message: 'Tag has been updated'
            })
        }

        setIsModalCreate(false)
        setLoaded(!loaded)
    }
    const handleCreateCancel = () => {
        setIsModalCreate(false)
    }

    return (
        <DiventryLayoutThreatDB>
            <DiventryMenu kernel={kernel} />
            <DiventryLayoutHeader
                title="ThreatDB Tagging"
                description="Threats database incident & profiling lists"
            />
            <DiventryLayoutContent>

                <Modal
                    title={modalMode === null ? "Creating a ThreatDB tagging" : "Updating ThreatDB tag"}
                    open={isModalCreate}
                    onOk={handleCreateOk}
                    onCancel={handleCreateCancel}
                >
                    <Form
                        // layout="vertical"
                        form={createForm}
                    >
                        <Form.Item label="Tag name" name="name">
                            <Input disabled={modalMode === null ? false : true} />
                        </Form.Item>

                        <Form.Item label="Tag brief" name="brief">
                            <Input />
                        </Form.Item>

                        <Form.Item label="Description" name="description">
                            <Input.TextArea />
                        </Form.Item>

                        <Form.Item label="Connection to this IP (output)" name="output">
                            <Select
                                style={{ width: '100%' }}
                                options={
                                    Object.keys(Responses).map((key) => {
                                        return ({ value: key, label: Responses[key] })
                                    })
                                }
                            />
                        </Form.Item>

                        <Form.Item label="Connection from this IP (input)" name="input">
                            <Select
                                style={{ width: '100%' }}
                                options={
                                    Object.keys(Responses).map((key) => {
                                        return ({ value: key, label: Responses[key] })
                                    })
                                }
                            />
                        </Form.Item>

                        <Form.Item label="Order" name="order">
                            <InputNumber />
                        </Form.Item>

                        <Form.Item label="Is a DoHzel filtering tag" name="dohzel">
                            <Switch checkedChildren="Included" unCheckedChildren="Excluded" />
                        </Form.Item>

                        <Form.Item label="Is a DoHzel default tag" name="dohzelDefault">
                            <Switch checkedChildren="Yes" unCheckedChildren="No" />
                        </Form.Item>

                        <Form.Item label="Tag color" name="color">
                            <ColorPicker showText format="hex" />
                        </Form.Item>

                    </Form>
                </Modal>

                <p>
                    <Space>
                        <Button type="primary" onClick={() => {
                            createForm.resetFields()
                            setModalMode(null)
                            setIsModalCreate(true)
                        }}>
                            Create new Tagging Control
                        </Button>

                    </Space>

                </p>
                <DiventryBlock>
                    <Table dataSource={table} columns={columns} />
                </DiventryBlock>
            </DiventryLayoutContent>

            <DiventryLayoutFooter kernel={kernel}/>
        </DiventryLayoutThreatDB>

    );
}