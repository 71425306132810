import { useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { Tag, Select, Switch, notification, theme } from 'antd';
import { DiventryBlock } from '../../../Templates/Layout';
import { LogTable } from '../../../Templates/Logs';
import { LockOutlined, UnlockOutlined } from '@ant-design/icons';

const { useToken } = theme;

export const LogsColors = {
    blocking: "4px solid #a14838",
    detecting: "4px solid #e58900",
    passed: "4px solid #22d933",
    bypassing: "4px solid #4974a5",
}

export const policies = {
    blocking: "✅ Blocking mode (Log & block)",
    detecting: "🕵️‍♂️ Detecting mode (Log only)",
    bypassing: "⚠️ Bypassing mode (no log & no block)",
}

export function SecurityPolicies({ kernel, type }) {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();

    const [loaded, setLoaded] = useState(true);
    const [loading, setLoading] = useState(false);

    const { token: design } = useToken();
    const [info, setInfo] = useState({});
    const [infoTags, setInfoTags] = useState({});
    const [expands, setExpands] = useState([]);
    const [securityLocked, setSecurityLocked] = useState([]);

    // async load
    useEffect(() => {
        async function fetch() {

            // fetch tags
            const tagsRes = await kernel.api.get(`/dohzel/tags`);
            if (tagsRes.error) {
                notification.error({
                    message: `Error fetching information`,
                    description: tagsRes.error
                });

                return;
            }

            // fetch info configuration
            var current = await kernel.api.get(`/dohzel/${type}/get?id=${params.id}`);
            if (current.error) {
                notification.error({
                    message: `Error fetching information`,
                    description: current.error
                });
                return;
            }

            // merge tag and info configuration
            const newProfileTags = {};
            for (var tag of tagsRes.data) {
                newProfileTags[tag.name] = {
                    ...tag,
                    activate: false,
                    locked: false
                };
            }
            if (!Array.isArray(current.data.security))
                current.data.security = [];
            for (var tags of current.data.security) {
                if (!newProfileTags[tags])
                    continue;
                newProfileTags[tags].activate = true;
            }

            if (!Array.isArray(current.data.securityLocked))
                current.data.securityLocked = [];
            for (var tags of current.data.securityLocked) {
                if (!newProfileTags[tags])
                    continue;
                newProfileTags[tags].locked = true;
            }

            if (current.data?.profileId && type == 'device') {
                // fetch info profile
                var profile = await kernel.api.get(`/dohzel/profile/get?id=${current.data?.profileId}`);
                setSecurityLocked(profile?.data?.securityLocked);
            }

            setInfo(current.data);
            setInfoTags(newProfileTags);
        }
        fetch();
    }, [params.id, loaded]);

    async function switchButton(item) {
        setLoading(true);
        const data = {
            id: info.id,
            security: [],
            securityLocked: []
        };
        infoTags[item.name].activate = !infoTags[item.name].activate;
        for (var key in infoTags) {
            const value = infoTags[key];
            if (value.activate === true)
                data.security.push(key);
            
            if (value.locked === true)
                data.securityLocked.push(key);
        }

        const ret = await kernel.api.post(`/dohzel/${type}/update`, data);
        if (!ret || ret.error) {
            notification.error({
                message: 'Update error',
                description: ret.error
            });
            return;
        }

        setLoaded(!loaded);
        setLoading(false);
    }

    const lockButton = async (item) => {
        setLoading(true);
        const data = {
            id: info.id,
            security: [],
            securityLocked: []
        };
        infoTags[item.name].locked = !infoTags[item.name].locked;
        for (var key in infoTags) {
            const value = infoTags[key];
            if (value.locked === true)
                data.securityLocked.push(key);

            if (value.activate === true)
                data.security.push(key);
        }

        const ret = await kernel.api.post(`/dohzel/${type}/update`, data);
        if (!ret || ret.error) {
            notification.error({
                message: 'Update error',
                description: ret.error
            });
            return;
        }

        setLoaded(!loaded);
        setLoading(false);
    };

    async function changePolicy(mode) {
        const data = {
            id: info.id,
            policy: mode
        };

        const ret = await kernel.api.post(`/dohzel/${type}/update`, data);
        if (!ret || ret.error) {
            notification.error({
                message: 'Update error',
                description: ret.error
            });
            return;
        }

        notification.success({
            message: `Profile security policy changed`,
            description: policies[mode]
        });

        setLoaded(!loaded);
    }

    const handleExpan = (entry) => {
        const item = expands?.find((expansion) => expansion === entry.id)
        if (item) {
            setExpands(expands?.filter((expansion) => expansion !== entry.id));
        } 
        else {
            setExpands([...expands, entry?.id]);
        }
    } 

    return (
        <>
            <DiventryBlock>
                <p>
                    <Select
                        value={info.policy}
                        style={{ minWidth: 300 }}
                        onChange={(key) => {
                            changePolicy(key);
                        }}
                        options={Object.keys(policies).map((key) => {
                            return ({
                                value: key,
                                label: policies[key]
                            });
                        })} />
                </p>
                <LogTable
                    kernel={kernel}
                    data={Object.values(infoTags).map((item) => {
                        return ({
                            id: item.name,
                            open: !!expands?.find((expansion) => expansion === item.name),
                            handleExpan: handleExpan,
                            left: <>
                                <Tag color={item.color} style={{
                                    padding: 5,
                                    fontSize: 18,
                                    fontWeight: 700
                                }}>#{item.name}</Tag>
                                <strong>{item.brief}</strong>
                            </>,
                            right: <>
                                    <Switch disabled={securityLocked?.includes(item.name)} loading={loading} value={item.activate} onClick={() => {
                                            switchButton(item);
                                        }} />

                                        {
                                            type == 'profile' && 
                                            <Switch 
                                                loading={loading}
                                                style={{marginLeft: 20}}
                                                checkedChildren={<LockOutlined />}
                                                unCheckedChildren={<UnlockOutlined />}
                                                defaultChecked
                                                value={item.locked} onClick={() => {
                                                    lockButton(item);
                                                }} 
                                            />
                                        }
                                    </>,
                            expand: item.description ? item.description : null
                        });
                })} />
            </DiventryBlock>
        </>
    );
}
