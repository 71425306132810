import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
    Button,
    Table,
    Space,
    notification,
    Tag
} from 'antd';
import { DiventryBlock } from '../../Templates/Layout';

const columns = [
    {
        title: 'product Name',
        dataIndex: 'productName',
        key: 'productName',
        render: (text) => (
            <div>{text}</div>
        )
    },
    {
        title: 'License used',
        dataIndex: 'quantity',
        key: 'quantity',
        render: (text, record) => (
            <div>{record?.deviceCount ?? 0} / {text} licences</div>
        )
    },
    {
        title: 'Start Date',
        dataIndex: 'startDate',
        key: 'startDate',
        sorter: (a, b) => a.startDate - b.startDate,
        render: (text) => (
            <div>{new Date(text).toLocaleString()}</div>
        )
    },
    {
        title: 'Next Billing Date',
        dataIndex: 'currentPeriodEnd',
        key: 'currentPeriodEnd',
        sorter: (a, b) => a.currentPeriodEnd - b.currentPeriodEnd,
        render: (text) => (
            <div>{new Date(text).toLocaleString()}</div>
        )
    },
    {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
        render: (text, record) => (
            <div>{record.currency.toUpperCase()} {text.toFixed(2) * (record?.quantity ?? 1)}</div>
        )
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (text) => {
            let color = text === "active" ? "green" : text === "canceled" ? "red" : "orange";
            return (
                <Tag color={color} style={{ fontWeight: 700 }}>
                    {text.charAt(0).toUpperCase() + text.slice(1)}
                </Tag>
            );
        }
    },
    // {
    //     dataIndex: 'actions',
    //     key: 'actions',
    //     align: "right",
    // },
];

export function SubscriptionsItems({ kernel, priceId }) {
    const [loaded, setLoaded] = useState(true);
    const [list, setList] = useState([]);

    useEffect(() => {
        async function fetchList() {
            const response = await kernel.api.get(`/user/subscriptions/list`);
            if (!response || response.error) {
                notification.error({
                    message: `Can not fetch subscriptions`,
                    description: response?.error || null
                });
                return;
            }

            const subscriptions = response.data;

            setList(subscriptions);
        }

        fetchList();
    }, [loaded]);

    const handleManage = async () => {
        try {
            const response = await kernel.api.get(`/user/subscriptions/manage`);
            if (response.error) {
                notification.error({
                    message: 'Error',
                    description: response.error,
                });
                return;
            }
    
            window.location.href = response.data.url;
        } catch (error) {
            notification.error({
                message: 'Error',
                description: error.message,
            });
        }
    };

    return (
        <DiventryBlock>
            <Table
                size="small"
                dataSource={list}
                columns={columns}
                tableLayout="fixed"
                footer={
                    () => (
                        list.length &&
                        <Space size="small" style={{marginTop: 20}}>
                            <Button type="primary" onClick={() => handleManage()}>
                                Manage my subscriptions
                            </Button>
                        </Space>
                    )
                }
            />
        </DiventryBlock>
    );
}
