
import { useEffect, useState, useCallback } from "react"
import { useParams, useLocation, useNavigate } from "react-router-dom"

import {
    Space,
    Button,
    Empty,
    theme
} from 'antd';

import {
    RightOutlined,
    DownOutlined
} from '@ant-design/icons'

const { useToken } = theme

export function LogsLine({ kernel, entry }) {
    const { token: design } = useToken()
    return (
        <>
            <tr
                key={`log-tr-${entry.id}`}
                style={{
                    border: "1px solid rgba(0,0,0,0.1)",
                }}>

                <td style={{ padding: 5, paddingLeft: 1 }}>
                    <div style={{
                        paddingLeft: entry.color ? 10 : null,
                        borderLeft: entry.color,
                        paddingTop: design.borderRadius,
                        paddingBottom: design.borderRadius
                    }}>
                        <Space>
                            {entry.expand ?
                                <Button
                                    type="text"
                                    onClick={() => {
                                        entry.handleExpan(entry)
                                    }}
                                >
                                    {entry.open === true ? <DownOutlined /> : <RightOutlined />}
                                </Button>
                                : null}
                            <div>
                                {entry.left}
                            </div>
                        </Space>
                    </div>
                </td>

                <td style={{ padding: 10, textAlign: "right" }}>
                    {entry.right}
                </td>
            </tr>

            {entry.open === true && entry.expand ?
                <tr key={`log-tr-more-${entry.id}`}>
                    <td colSpan={2} style={{ padding: 10 }}>
                        {entry.expand}
                    </td>
                </tr>
                : null}
        </>
    )
}

export function LogTable({ kernel, data }) {
    const { token: design } = useToken()
    return (
        <div style={{
            border: `${design.lineWidth}px ${design.lineType} ${design.colorBorder}`,
            borderRadius: design.borderRadius
        }}>
            {data.length === 0 ?
                <div style={{padding: 30}}>
                    <Empty />
                </div>
                :
                <table
                    width="100%"
                    style={{
                        border: `${design.lineWidth}px ${design.lineType} ${design.colorBorder}`,
                        borderCollapse: "collapse",
                        borderStyle: "hidden",
                    }}
                >
                    {data.map((entry) => {
                        return (<LogsLine kernel={kernel} entry={entry} />)
                    })}
                </table>
            }



        </div>
    )
}
