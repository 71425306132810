import { useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import {
    Space,
    Input,
    Button,
    Tag,
    Spin,
    Switch,
    Popconfirm,
    notification
} from 'antd';
import {
    CompassOutlined,
    GlobalOutlined, DeleteOutlined
} from '@ant-design/icons';
import { DiventryBlock } from '../../../Templates/Layout';
import { LogTable } from '../../../Templates/Logs';


export function ABList({ kernel, list = "allow", color = "black", type }) {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();

    const [loaded, setLoaded] = useState(true);
    const [loading, setLoading] = useState(false);

    const [input, setInput] = useState(null);
    const [rules, setRules] = useState([]);
    const [oppositRules, setOppositRules] = useState([]);
    const [confirmVisible, setConfirmVisible] = useState(false);
    const [confirmActiveVisible, setConfirmActiveVisible] = useState(false);

    useEffect(() => {
        async function fetch() {
            setLoading(true);
            const ret = await kernel.api.get(`/dohzel/ablist/list?${type}=${params.id}&list=${list}`);
            setLoading(false);
            if (!ret || ret.error) {
                notification.error({
                    message: 'Error adding rule',
                    description: ret.error
                });
                return;
            }
            setRules(ret.data);
        }
        fetch();
    }, [params.id, loaded]);

    useEffect(() => {
        async function fetch() {
            setLoading(true);
            const ret = await kernel.api.get(`/dohzel/ablist/list?${type}=${params.id}&list=${list == 'allow' ? 'block' : 'allow'}`);
            setLoading(false);
            setOppositRules(ret.data);
        }
        fetch();
    }, [list, params.id, loaded]);

    async function add() {
        const data = {
            list: list,
        };
        data[type] = params.id
        
        var masked = input.split("/");
        var detection = "Domain";
        if (/^[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}$/.test(masked[0])) {
            detection = "IPv4";
            data.type = "cidr";
            data.cidr = input;
        }
        else if (masked[0].indexOf(":") > 0) {
            detection = "IPv6";
            data.type = "cidr";
            data.cidr = input;
        }
        else {
            data.type = "domain";
            const t = input.split(".");
            if (t[0] === "*") {
                data.domainWildcard = true;
                t.shift();
                data.domain = t.join(".");
            }

            else
                data.domain = input;
        }

        const ret = await kernel.api.post(`/dohzel/ablist/create`, data);
        if (!ret || ret.error) {
            notification.error({
                message: 'Error adding rule',
                description: ret.error
            });
            return;
        }

        setInput("");
        setLoaded(!loaded);
    }

    const disabledInList = async () => {
        const item = oppositRules?.find((domain) => domain.cidr == input || domain.domain == input)
        const data = {
            id: item.id,
            activate: false
        };
        setLoading(true);
        const ret = await kernel.api.post(`/dohzel/ablist/update`, data);
        setLoading(false);

        setLoaded(!loaded);
    }

    const changeActive = async (item) => {
        const data = {
            id: item.id,
            activate: !item.activate
        };
        setLoading(true);
        const ret = await kernel.api.post(`/dohzel/ablist/update`, data);
        setLoading(false);
        if (!ret || ret.error) {
            notification.error({
                message: 'Error changing mode',
                description: ret.error
            });
            return;
        }

        setLoaded(!loaded);
    }

    return (
        <>
            <DiventryBlock>
                <div style={{ display: "flex", gap: "10px", paddingBottom: 10 }}>
                    <Input
                        placeholder="Add a domain to the list, you can use wildcard or a single IP address and network range"
                        onChange={(el) => {
                            setInput(el.target.value);
                        }}
                        value={input}
                        allowClear={true}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                if (oppositRules?.find((domain) => domain.cidr == input || domain.domain == input)?.activate) {
                                    setConfirmVisible(true);
                                }
                                else {
                                    add();
                                }
                                return;
                            }
                        }} />
                        {
                            !oppositRules?.find((domain) => domain.cidr == input || domain.domain == input)?.activate && 
                            <Button onClick={add}>Add</Button>
                        }
                        {
                            oppositRules?.find((domain) => domain.cidr == input || domain.domain == input)?.activate && 
                            <Popconfirm
                                placement="left"
                                title="Domain conflit"
                                description={`Domain already added in the ${list == 'allow' ? 'block' : 'allow'} list. If you cantinue the domain will be disabled in the ${list == 'allow' ? 'block' : 'allow'} list`}
                                okText="Continue"
                                visible={confirmVisible}
                                onClick={() => {
                                    setConfirmVisible(true);
                                }}
                                cancelText="Cancel"
                                onCancel={() => {
                                    setConfirmVisible(false);
                                }}
                                onConfirm={async () => {
                                    add();
                                    disabledInList();
                                    setConfirmVisible(false);
                                }}
                            >
                                <Button>Add</Button>
                            </Popconfirm>
                        }
                </div>
                <Spin size="large" spinning={loading}>
                    <LogTable
                        kernel={kernel}
                        data={rules.map((item) => {
                            return (
                                {
                                    color: color,
                                    left: <div style={{
                                        fontWeight: 600,
                                        fontSize: 17
                                    }}>{item.type === "cidr" ?
                                        item.cidr :
                                        <>
                                            {item.domainWildcard === true ?
                                                <span style={{ opacity: 0.5 }}>*.</span>
                                                : null}
                                            {item.domain}
                                        </>}
                                    </div>,
                                    right: <Space>
                                        {item.type === "cidr" ?
                                            <Tag
                                                color="geekblue"
                                                bordered={false}
                                                style={{
                                                    fontSize: 10,
                                                    fontWeight: 700,
                                                    cursor: "pointer"
                                                }}
                                            >
                                                <GlobalOutlined /> IP
                                            </Tag>
                                            : null}

                                        {item.type === "domain" ?
                                            <Tag
                                                color="purple"
                                                bordered={false}
                                                style={{
                                                    fontSize: 10,
                                                    fontWeight: 700,
                                                    cursor: "pointer"
                                                }}
                                            >
                                                <CompassOutlined /> Domain
                                            </Tag>
                                            : null}
                                        {
                                            oppositRules?.find((domain) => domain.cidr == item.domain || domain.domain == item.domain)?.activate &&
                                            <Popconfirm
                                                placement="left"
                                                title="Domain conflit"
                                                description={`Domain already added in the ${list == 'allow' ? 'block' : 'allow'} list. If you cantinue the domain will be disabled in the ${list == 'allow' ? 'block' : 'allow'} list`}
                                                okText="Continue"
                                                visible={confirmActiveVisible}
                                                onClick={() => {
                                                    if (!item.activate) {
                                                        setConfirmActiveVisible(true);
                                                    }
                                                    else {
                                                        changeActive(item);
                                                    }
                                                }}
                                                cancelText="Cancel"
                                                onCancel={() => {
                                                    setConfirmActiveVisible(false);
                                                }}
                                                onConfirm={async () => {
                                                    setConfirmActiveVisible(false);
                                                    changeActive(item);
                                                    disabledInList();
                                                }}
                                            >
                                                <Switch
                                                    size="small"
                                                    value={item.activate}
                                                    onChange={async () => {
                                                        
                                                    }} 
                                                />
                                            </Popconfirm>
                                        }

                                        {
                                            !oppositRules?.find((domain) => domain.cidr == item.domain || domain.domain == item.domain)?.activate &&
                                            <Switch
                                                size="small"
                                                value={item.activate}
                                                onChange={async () => {
                                                    changeActive(item);
                                                }} 
                                            />
                                        }
                                        

                                        <Button size="small" onClick={async () => {
                                            const data = { id: item.id };

                                            setLoading(true);
                                            const ret = await kernel.api.post(`/dohzel/ablist/remove`, data);
                                            setLoading(false);
                                            if (!ret || ret.error) {
                                                notification.error({
                                                    message: 'Error deleting entry',
                                                    description: ret.error
                                                });
                                                return;
                                            }

                                            setLoaded(!loaded);
                                        }}><DeleteOutlined /></Button>
                                    </Space>,
                                }
                            );
                        })} />
                </Spin>
            </DiventryBlock>
        </>
    );
}
