import { useEffect, useState } from 'react'
import { useNavigate, useParams } from "react-router-dom"

import {
    Button,
    Tabs,
    Alert,
    Tag,
    Space,
    Popconfirm,
    Card,
    Table,
    Modal,
    Form,
    Input,
    Select,
    notification
} from 'antd'

import {
    CompassOutlined,
    EditOutlined, DeleteOutlined
} from '@ant-design/icons';

import { DiventryBlock } from '../../../../Templates/Layout'

import Brands from '../../Brands/Index'

export function ConnectRules({ kernel }) {
    const [form] = Form.useForm()

    const [loaded, setLoaded] = useState(true)

    const [isModal, setModal] = useState(false)
    const [update, setUpdate] = useState(false)
    const [data, setData] = useState({})
    const [devices, setDevices] = useState([])
    const [devicesLoading, setDevicesLoading] = useState(true)

    // for navigation
    const navigate = useNavigate();
    const { id } = useParams();

    // async load
    useEffect(() => {
        // fetch devices
        setDevicesLoading(true)
        async function fetchDevices() {
            const raw = await kernel.api.get(`/dohzel/connect/devices?profileId=${id}`)
            if (raw.error || !raw.data) {
                // notification.error({
                //     message: "Can not load Connect devices"
                // })
                setTimeout(fetchDevices, 2000)
                return
            }
            setDevices(raw.data)
            setTimeout(() => setDevicesLoading(false), 500)
        }
        fetchDevices();
    }, [id, isModal]);

    // load data source
    const [rules, setRules] = useState([])
    const [rulesLoading, setRulesLoading] = useState(true)
    useEffect(() => {
        setRulesLoading(true)
        async function fetchRules() {
            const raw = await kernel.api.get(`/dohzel/connect/list?profileId=${id}`)
            if (raw.error || !raw.data) {
                // notification.error({
                //     message: "Can not load Connect devices"
                // })
                setTimeout(fetchRules, 2000)
                return
            }
            setRules(raw.data)
            setTimeout(() => setRulesLoading(false), 500)
        }
        fetchRules();
    }, [id, loaded]);

    useEffect(() => {
        form.setFieldsValue(data)
    }, [id, data])

    async function onOk() {
        const values = form.getFieldsValue()
        values.profileId = id

        if (update)
            values.id = update

        const ret = update ?
            await kernel.api.post(`/dohzel/connect/update`, values) :
            await kernel.api.post(`/dohzel/connect/create`, values)

        if (ret.error) {
            notification.error({
                message: update ? "Cannot update the rule" : "Cannot create the rule",
                description: ret.error
            })
            return
        }
        notification.success({
            message: update ? "Rule updated!" : "Rule created!"
        })
        setLoaded(!loaded)
        setModal(false)
    }

    async function onCancel() {
        setModal(false)
    }

    return (<>
        <Modal
            title={update ? "Update Connect rule" : "Insert DoHzel Connect rule"}
            open={isModal}
            onOk={onOk}
            onCancel={onCancel}
            forceRender
        >
            <Form
                form={form}
            >
                <Form.Item label="Type" name="type">
                    <Select
                        style={{ width: '100%' }}
                        options={[
                            {
                                value: "domain",
                                label: "Apply rule on domain or subdomain"
                            },
                            {
                                value: "regex",
                                label: "Use regex against domain"
                            }
                        ]}
                        disabled={update}
                        onChange={(id) => {
                            setData({ ...data, type: id })
                        }}
                    />
                </Form.Item>

                {data.type === "domain" ? <>
                    <Form.Item label="Domain name" name="domain">
                        <Input />
                    </Form.Item>
                </> : null}

                {data.type === "regex" ? <>
                    <Form.Item label="Regex based on domain" name="regex">
                        <Input />
                    </Form.Item>
                </> : null}

                <Form.Item label="Forwarded Device" name="forwardedDeviceId">
                    <Select
                        placeholder={devicesLoading === true ? "Loading..." : "Select a device"}
                        style={{ width: '100%' }}
                        options={devices.map((item) => {
                            return ({
                                value: item.id,
                                label: <>
                                    <strong>{item.name}</strong>
                                    {item.connectCap === true ?
                                        <Tag color="green" bordered={false}>Connected</Tag> :
                                        <Tag color="orange" bordered={false}>Disconnected</Tag>
                                    }
                                </>
                            })
                        })}
                        loading={devicesLoading} />
                </Form.Item>
            </Form>
        </Modal>

        <div style={{ paddingTop: 5 }}>
            <Button
                type="primary"
                onClick={() => {
                    setLoaded(!loaded)
                    setData({
                        type: "domain",
                        regex: "",
                        domain: "",
                        forwardedDeviceId: ""
                    })
                    setUpdate(null)
                    setModal(true)
                }}
            >
                Add rule
            </Button>


            <Table
                dataSource={rules}
                columns={[
                    // {
                    //   title: 'ID',
                    //   dataIndex: 'ID',
                    //   key: 'id',
                    // },
                    {
                        title: 'Type',
                        dataIndex: 'type',
                        key: 'type',
                    },
                    {
                        title: 'Rule',
                        dataIndex: 'rule',
                        key: 'rule',
                        render: (text, record, index) => {
                            if (record.type === "domain")
                                return (<Tag color="#108ee9">{record.domain}</Tag>)
                            else if (record.type === "regex")
                                return (<Tag color="#87d068">{record.regex}</Tag>)
                            return ("-")
                        }
                    },
                    {
                        title: 'Forwarded device',
                        dataIndex: 'forwardedDeviceInfo',
                        key: 'forwardedDeviceInfo',
                        render: (text, record, index) => {
                            return (<Brands.BrandedDevice connectStatus={true} device={record.forwardedDevice} />)
                        }
                    },
                    {
                        title: 'Actions',
                        dataIndex: 'actions',
                        key: 'actions',
                        align: "right",
                        render: (text, record, index) => {
                            return (<Space.Compact size="small">
                                <Button type="default" onClick={() => {
                                    setLoaded(!loaded)
                                    setData({
                                        type: record.type,
                                        regex: record.regex,
                                        domain: record.domain,
                                        forwardedDeviceId: record.forwardedDeviceId
                                    })
                                    setUpdate(record.id)
                                    setModal(true)
                                }}><EditOutlined /></Button>

                                <Popconfirm
                                    title="You want to delete this rule?"
                                    onCancel={() => {
                                        // setConfirmVisible(false);
                                    }}
                                    onConfirm={async () => {
                                        const ret = await kernel.api.post(`/dohzel/connect/remove`, {
                                            profileId: id,
                                            id: record.id
                                        })
                                        if (ret.error) {
                                            notification.error({
                                                message: "Cannot remove the rule",
                                                description: ret.error
                                            })
                                            return
                                        }
                                        notification.success({
                                            message: "Rule removed"
                                        })
                                        setLoaded(!loaded)
                                    }}
                                >
                                    <Button type="default" ><DeleteOutlined /></Button>
                                </Popconfirm>


                            </Space.Compact>)
                        }
                    },
                ]}
            />
        </div>
    </>)
}

export function ConnectDevices({ kernel }) {

    const [loaded, setLoaded] = useState(true)

    const [isModal, setModal] = useState(false)
    const [update, setUpdate] = useState(false)
    const [data, setData] = useState({})
    const [devices, setDevices] = useState([])
    const [devicesLoading, setDevicesLoading] = useState(true)

    // for navigation
    const navigate = useNavigate();
    const { id } = useParams();

    // async load
    useEffect(() => {
        // fetch devices
        setDevicesLoading(true)
        async function fetchDevices() {
            const raw = await kernel.api.get(`/dohzel/connect/devices?profileId=${id}`)
            if (raw.error || !raw.data) {
                // notification.error({
                //     message: "Can not load Connect devices"
                // })
                setTimeout(fetchDevices, 2000)
                return
            }
            setDevices(raw.data)
            setTimeout(() => setDevicesLoading(false), 500)
        }
        fetchDevices();
    }, [id, loaded]);

    const columns = [
        {
            title: 'Device',
            dataIndex: 'device',
            key: 'device',
            render: (text, record, index) => {
                return (<Brands.BrandedDevice connectStatus={true} device={record} />)
            }
        },
        {
            title: 'Last beat',
            dataIndex: 'beatAt',
            key: 'beatAt',
            align: "right",
            render: (text, record, index) => {
                return (new Date(record.beatAt).toLocaleString())
            }
        },
        // {
        //     title: 'Address',
        //     dataIndex: 'address',
        //     key: 'address',
        // },
    ];

    return (<>
        <Table dataSource={devices} columns={columns} />
    </>)
}

export function Connect({ kernel }) {

    // const onChange = (key) => {
    //     console.log(key);
    // }

    return (
        <>
            <DiventryBlock >
                <Alert
                    type="info" showIcon
                    description="DoHZel Connect allows you to broadcast your own DNS in your profile, so devices using it can access local domains or redirect URLs. For more information, please refer to the documentation."
                    closable
                //   onClose={onClose}
                />
                <Tabs defaultActiveKey="1" items={[
                    {
                        key: 'rules',
                        label: 'Rules',
                        children: <ConnectRules kernel={kernel} />,
                    },
                    {
                        key: 'devices',
                        label: 'Available devices',
                        children: <ConnectDevices kernel={kernel} />,
                    }
                ]}
                    // onChange={onChange}
                />

            </DiventryBlock>

        </>
    );
} 