import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom"
import {
    Button,
    Table,
    Space,
    notification,
    Tag,
    Select
} from 'antd';
import {
    DiventryBlock
} from '../../Templates/Layout';

import { SubscriptionsItems } from './SubscriptionItems'

const { Option } = Select;

export function Subscriptions({ kernel }) {
    const [loaded, setLoaded] = useState(true);
    const [list, setList] = useState([]);
    const { id } = useParams();
    
    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text) => (
                <div>{text}</div>
            )
        },
        {
            title: 'Total license used',
            dataIndex: 'totalAssignedDevices',
            key: 'totalAssignedDevices',
            render: (text, record) => (
                <div>{text} / {record?.totalQuantity}</div>
            )
        },
        // {
        //     title: 'Status',
        //     dataIndex: 'active',
        //     key: 'active',
        //     render: (text) => (
        //         <Tag color={text ? 'green' : 'red'}>
        //             {text ? 'Active' : 'Inactive'}
        //         </Tag>
        //     )
        // },
        // {
        //     title: 'Created',
        //     dataIndex: 'created',
        //     key: 'created',
        //     sorter: (a, b) => a.created - b.created,
        //     render: (text) => (
        //         <div>{new Date(text).toLocaleString()}</div>
        //     )
        // },
        // {
        //     title: 'Last subscription price',
        //     dataIndex: 'priceId',
        //     key: 'priceId',
        //     render: (_, record) => (
        //         record.hasActiveSubscription && record.selectedPriceId ? 
        //         <Tag color="blue">
        //                 Subscribed to: {record.prices.find(price => price.id === record.selectedPriceId)?.currency.toUpperCase()} {record.prices.find(price => price.id === record.selectedPriceId)?.unit_amount / 100}
        //         </Tag>
        //         :
        //         <Tag color="gray">
        //                 No subscription
        //         </Tag>
        //     )
        // },
        {
            title: 'Duration',
            dataIndex: 'priceId',
            key: 'priceId',
            render: (_, record) => (
                <Select
                    placeholder="Select a price"
                    onChange={(value) => {
                        var products = list;
                        products.find(product => product?.id == record.id).priceId = value;
                        setList(products);
                        updateButtonList(products)
                    }}
                    style={{ width: 250 }}
                >
                    {record.prices?.map(price => (
                        <Option key={price.id} value={price.id}>
                            {/* {price.currency.toUpperCase()} {price.unit_amount / 100} */}
                            {price?.name ?? (`${price.currency.toUpperCase()} ${price.unit_amount / 100}`)}
                        </Option>
                    ))}
                </Select>
            )
        },
        {
            title: 'Quantity',
            dataIndex: 'quantityId',
            key: 'quantityId',
            render: (_, record) => (
                <Select
                    placeholder="Select a quantity"
                    onChange={(value) => {
                        var products = list;
                        products.find(product => product?.id == record.id).quantityId = value;
                        setList(products);
                        updateButtonList(products)
                    }}
                    style={{ width: 150 }}
                >
                    {[10, 25, 50, 100, 250, 500, 1000, 1500, 2000, 2500, 3000, 5000, 10000, 20000, 50000]?.map(quantity => (
                        <Option key={quantity} value={quantity}>
                            {quantity}
                        </Option>
                    ))}
                </Select>
            )
        },
        {
            title: 'Action',
            dataIndex: 'actions',
            key: 'actions',
            align: "right",
            width: '80px',
        },
    ];

    useEffect(() => {
        async function fetchList() {
            const response = await kernel.api.get("/user/products/list");
            if (!response || response.error) {
                notification.error({
                    message: `Cannot fetch products`,
                    description: response?.error || null
                });
                return;
            }

            setList(response.data?.filter(item => item.name && item.name.startsWith("D")));
            updateButtonList(response.data?.filter(item => item.name && item.name.startsWith("D")))
        }

        fetchList();
    }, [loaded]);

    const updateButtonList = (_list=list) => {
        setList(_list?.map((item) => ({
            ...item,
            actions: (
                // !item.hasActiveSubscription ? 
                <Space size="small" style={{background: 'transparent'}}>
                    <Button
                        onClick={() => handleView(item.id, item.priceId, item.quantityId)}
                        disabled={!item.quantityId || !item.priceId}
                    >
                        Buy
                    </Button>
                </Space>
                // : 
                // <Space size="small" style={{background: 'transparent', marginRight: 23}}>
                //     ✔
                // </Space>
            ),
        }
    )));
    }

    const handleView = async (productId, priceId, quantityId) => {
        if (!priceId) {
            notification.warning({
                message: 'Please select a price',
            });
            return;
        }
    
        if (!quantityId) {
            notification.warning({
                message: 'Please select a quantity',
            });
            return;
        }
    
        try {
            console.log('id : ', id)
            const response = await kernel.api.get(`/user/products/create-checkout-session?productId=${productId}&priceId=${priceId}&quantity=${quantityId}`);
            if (response.error) {
                notification.error({
                    message: 'Could not initiate checkout',
                    description: response.error,
                });
                return;
            }
    
            // Rediriger vers l'URL de session Stripe
            window.location.href = response.data.url;
        } catch (error) {
            notification.error({
                message: 'Error',
                description: error.message,
            });
        }
    };    

    return (
        <>
            <DiventryBlock>
                <Table
                    size="small"
                    dataSource={list}
                    columns={columns}
                    tableLayout="fixed"
                />
            </DiventryBlock>

            <SubscriptionsItems kernel={kernel} priceId={id}></SubscriptionsItems>
        </>
    );
}
