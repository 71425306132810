
import { useEffect, useState, useCallback } from "react"
import { useParams, useNavigate } from "react-router-dom"

import {
    notification
} from 'antd';

import { DiventryMenu } from '../../Templates/Menu'
import {
    DiventryLayout,
    DiventryLayoutHeader,
    DiventryLayoutContent,
    DiventryLayoutFooter,
    DiventryBlock,
    DiventrySide
} from '../../Templates/Layout'


import { Information } from './Information'
import { Emails } from './Emails'
import { Billing } from './Billing'
import { PaiementMethod } from './PaiementMethod'
import { Share } from './Share'
import { API } from './API'
import { Invoices } from './Invoices'
import { Subscriptions } from './Subscriptions'

const pages = {
    'information': {
        key: 'information',
        title: "Account Informations",
        description: "Your personnal information",
        ref: Information
    },
    'emails': {
        key: 'emails',
        title: "Emails",
        description: "Emails addresses linked to your account",
        ref: Emails
    },
    'billing': {
        key: 'billing',
        title: "Billing Informations",
        // description: "Specify relevant network ranges to enable effective grouping during network analysis. Both private and public address ranges are supported.",
        ref: Billing
    },
    'payment-method': {
        key: 'payment-method',
        title: "Payment Method",
        // description: "Specify relevant network ranges to enable effective grouping during network analysis. Both private and public address ranges are supported.",
        ref: PaiementMethod
    },
    'invoices': {
        key: 'invoices',
        title: "Invoices",
        ref: Invoices
    },
    'subscriptions': {
        key: 'subscriptions',
        title: "Subscriptions",
        ref: Subscriptions
    },
    'share': {
        key: 'share',
        title: "Share Code",
        description: "Securely exchange and share identity on Hafnova software",
        ref: Share
    },
    'api': {
        key: 'api',
        title: "API Keys",
        description: "Set up API keys for programmatically accessing Hafnova software",
        ref: API
    }
}


export function Account({ kernel }) {
    let params = useParams();

    const [key, setKey] = useState(null);
    const [page, setPage] = useState(null);

    useEffect(() => {
        const page = pages[params.visionPage]
        setKey(params.visionPage)
        setPage(page)
    }, [params.visionPage])

    const [loading, setLoading] = useState(true)
    const [loaded, setLoaded] = useState(true)
    const [activeKey, setActiveKey] = useState(null)
    const [width, setWidth] = useState()
    const [error, setError] = useState(null)
    const [data, setData] = useState({})

    const navigate = useNavigate()

    useEffect(() => {
        setLoading(true)
        var interval = null

        const loader = async () => {
            var response = await kernel.api.get("/user/me", 1000 * 60)
            if (response.error) {
                notification.error({
                    message: `Error fetching information`,
                    description: <>{response.error}</>,
                    placement: "topRight",
                })
                interval = setTimeout(loader, 10000);
                return
            }
            setData(response.data)
            setLoading(false)
        }
        loader()

        return () => {
            if (interval) clearTimeout(interval)
        }

    }, [loaded])


    const onUpdate = async (form) => {
        if (!form) return (setLoaded(!loaded))
        var response = await kernel.api.post("/user/update", form)
        if (response.error) {
            notification.error({
                message: `Error updating account`,
                description: <>{response.error}</>,
                placement: "topRight",
            })

            return
        }

        notification.success({
            message: `Account`,
            description: "Information Updated",
            placement: "topRight",
        })
        setLoaded(!loaded)
    }

    const Switch = page?.ref
    return (
        <DiventryLayout>
            <DiventryMenu kernel={kernel} />
            <DiventryLayoutHeader
                title={page?.title}
                description={page?.description}
            />
            <DiventryLayoutContent>

                {Switch ?
                    <DiventrySide
                        selectedKeys={[params.visionPage]}
                        menu={[
                            {
                                label: 'Account information',
                                key: 'information',
                            },
                            {
                                label: 'Emails',
                                key: 'emails',
                            },
                            {
                                label: 'Billing information',
                                key: 'billing',
                            },
                            {
                                label: 'Payment Method',
                                key: 'payment-method',
                            },
                            {
                                label: 'Subscriptions',
                                key: 'subscriptions',
                            },
                            {
                                label: 'Invoices',
                                key: 'invoices',
                            },
                            {
                                label: 'Share Code',
                                key: 'share',
                            },
                            {
                                label: 'API keys',
                                key: 'api',
                            },
                            { type: 'divider' },
                            {
                                label: 'Log out',
                                key: 'disconnect',
                                danger: true
                            },
                        ]}
                        onClick={(e) => {
                            if (e.key === "disconnect") {
                                notification.success({
                                    message: `Disconnected`,
                                    description: "Your session has been closed",
                                })
                                kernel.session.store("")
                                navigate(`/session/login`)
                                return
                            }

                            navigate(`/account/${e.key}`)
                        }}
                    >
                        <Switch onUpdate={onUpdate} values={data} kernel={kernel} />
                    </DiventrySide>
                    : null}
            </DiventryLayoutContent>

            <DiventryLayoutFooter kernel={kernel}/>

        </DiventryLayout>
    )
}