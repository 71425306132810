import React, {useState} from 'react';
import { Form, Input, Button, Modal, Switch, InputNumber, notification, Descriptions, Row, Col, Space, Tooltip } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import {
    DiventryBlock
} from './Layout'
import { UtilsInputLang } from './Utils';

const PasswordReset = ({ kernel }) => {
  const [form] = Form.useForm();
  const [isModal, setIsModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const validatePassword = (_, value) => {
    if (!value || value.length < 6) {
      return Promise.reject(new Error('The password is at least 6 characters'));
    }
    return Promise.resolve();
  };

  const validateConfirmPassword = ({ getFieldValue }) => ({
    validator(_, value) {
      if (!value || getFieldValue('newPassword') === value) {
        return Promise.resolve();
      }
      return Promise.reject(new Error('The two passwords do not match'));
    }
  });

  const onFinish = async (form) => {
        setLoading(true)
        var response = await kernel.api.post("/user/update", form)
        if (response.error) {
            notification.error({
                message: `Error updating account`,
                description: <>{response.error}</>,
                placement: "topRight",
            })

            return
        }
        setLoading(false)

        handleCancel();

        notification.success({
            message: `Password`,
            description: "Password Updated",
            placement: "topRight",
        })
    }

  const handleCancel = () => {
      setIsModal(false)
  }

  return (
    <>
      <Button type="primary" onClick={() => setIsModal(true)}>
        Change Password
      </Button>
      <Modal 
        title="Reset your password" 
        open={isModal} 
        onCancel={handleCancel}
        footer={null}
        >
        <Form
            layout="vertical"
            onFinish={onFinish}
        >

            <Form.Item
              name="oldPassword"
              label="Your old password"
              rules={[
                { required: true, message: 'Your old password is required' },          
                { validator: validatePassword }
              ]}
            >
              {/* <Tooltip trigger={['focus']} title={'Your old password at least 6 characters'} placement="topLeft" overlayClassName="numeric-input"> */}
                <Input.Password prefix={<LockOutlined />} />
              {/* </Tooltip> */}
            </Form.Item>

            <Form.Item
              name="newPassword"
              label="Your new password"
              rules={[
                { required: true, message: 'Your new password is required' },
                { validator: validatePassword }
              ]}
            >
              <Input.Password prefix={<LockOutlined />} />
            </Form.Item>

            <Form.Item
                  name="confirmPassword"
                  label="Confirm your new password"
                  dependencies={['newPassword']}
                  rules={[
                    { required: true, message: 'Confirm your new password is required' },
                    validateConfirmPassword
                  ]}
                >
                  <Input.Password prefix={<LockOutlined />} />
                </Form.Item>

                <Form.Item>
                  <Button onClick={() => setIsModal(false)} loading={loading}>
                    Cancel
                  </Button>
                  <Button style={{marginLeft: 20}} loading={loading} type="primary" htmlType="submit">
                    Change Password
                  </Button>
              </Form.Item>

        </Form>
    </Modal>
    </>
  );
};

export default PasswordReset;
